/*
 * @Author: chenxing
 * @LastEditors: chenxing
 */
/*eslint-disable*/
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import _axios from './utils/axios';
import { clearObj, interFace } from './utils/utils.js';
import { uuid } from 'vue-uuid';
import "./styles/tn.scss"

//自定义滚动条
import ScrollBar from './directives/scrollbar';
import HasPermission from './directives/hasPermission';
//对应的css
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import store from './store';
import components from './config/antDesignImport.js';
import '@/styles/base.less';
// 组件中文化
import moment from 'moment';
import 'moment/dist/locale/zh-cn';
moment.locale('zh-cn');

import SvgIcon from '@/components/SvgIcon.vue';
import './assets/icons';
import { EventEmitter } from '@/utils/utils.js';

import './utils/statistics.js';
import axios from 'axios';

const app = createApp(App);
app.component('svg-icon', SvgIcon);
app.provide('$bus', new EventEmitter());

app.directive('fixed-scroll', ScrollBar);
app.directive('hasPermission', HasPermission);

app.use(components);
app.use(store);
app.use(router);
const filterOptions = {
  optionFilterProp: 'label',
  filterOption: true,
  showSearch: true,
};
const multipleFilterOptions = {
  optionFilterProp: 'label',
  filterOption: true,
  showSearch: true,
  mode: 'multiple',
  optionLabelProp: 'label',
  maxTagCount: 1,
  showArrow: true,
  allowClear: true,
  dropdownMatchSelectWidth: false,
};

axios.defaults.timeout=600000;

app.config.globalProperties.$axios = _axios;
app.config.globalProperties.$UUID = uuid;
app.config.globalProperties.$clearObj = clearObj;
app.config.globalProperties.$interFace = interFace;
app.config.globalProperties.$filterOptions = filterOptions;
app.config.globalProperties.$multipleFilterOptions = multipleFilterOptions;
app.config.devtools = process.env.NODE_ENV === 'development';
app.mount('#app');

(() => {
  let now = new Date().getTime();
  var versionScript = document.createElement('script');
  versionScript.src = location.origin + '/version.js?v=' + new Date().getTime();
  var s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(versionScript, s);
  localStorage.lastSyncVersionTime = now;
  window.getVersion = version => {
    localStorage.frontendVersion = version; // 保存 以便下次使用判断
  };
})();
